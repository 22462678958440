









































































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator';

import { shuffle } from 'lodash';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import Question from '@/components/Exercise/Question/Question.vue';
import QuestionLoading from '@/components/Exercise/Question/QuestionLoading.vue';
import QuestionAlternative from '@/components/Exercise/RadioAlternative/RadioAlternative.vue';
import AlternativeObjective from '@/components/Question/AlternativeObjective/index.vue';
import AlternativeTruthyOrFalsy from '@/components/Question/AlternativeTruthyOrFalsy/index.vue';
import AlternativeSummation from '@/components/Question/AlternativeSummation/index.vue';
import AlternativeRelateColumns from '@/components/Question/AlternativeRelateColumns/index.vue';
import SubjectiveQuestionInput from '@/components/Question/SubjectiveQuestionInput/index.vue';
import VideoHelpResolution from '@/components/Exercise/HelpResolution/VideoHelpResolution/index.vue';
import QuestionHelpResolution from '@/components/Exercise/HelpResolution/QuestionHelpResolution/index.vue';
import ResolutionRelateColumns from '@/components/Question/ResolutionRelateColumns/index.vue';
import ResolutionSummation from '@/components/Question/ResolutionSummation/index.vue';
import ResolutionTruthyOrFalsy from '@/components/Question/ResolutionTruthyOrFalsy/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import ButtonMovie from '@/components/ButtonMovie/ButtonMovie.vue';
import ButtonReportProblem from '@/components/ButtonReportProblem/ButtonReportProblem.vue';
import Footer from '@/components/Footer/Footer.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import Loading from '@/components/Loading/Loading.vue';
import EyeIcon from '@/assets/icons/Eye.vue';
// import ModalCreditsWarning from '@/components/Modal/ModalCreditsWarning/index.vue';
import ExLoading from '@/globalComponents/ExLoading/index.vue';

import RelatedQuestions from '../../components/RelatedQuestions/RelatedQuestions.vue';
import RelatedQuestionsLoading from '../../components/RelatedQuestions/RelatedQuestionsLoading.vue';

import QuestionService from '@/services/Question/QuestionService';
import SimulationService from '@/services/Simulation/SimulationService';
import { GetQuestions } from '@/services/Question/IQuestion';

// import HistoricService from '@/services/Historic/historicService';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';
// import ActiveModal from '@/share/Util/ActiveModal';

// import {
//   Option, OptionsAnswer, QuestionViewed,
// } from '../../interface';
import { OptionsAnswer, OptionsAnswerTrueOrFalse } from '../../interface';
import {
  Question as IQuestion,
  ITag,
  IOption,
  INewAlternativesTrueOrFalse,
  IRelateColumnsAnswers,
} from '@/globalInterfaces/Questions';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';
import { TAG_TYPES, TAG_TYPE_MODEL, TYPE_RESOLUTION } from '@/constant/Question';
import { ACTIONS } from '@/constant/Actions';
import { MATERIAL_TYPES } from '@/constant/MaterialTypesTable';

// import Credits, { ContextOptionsCredits } from '@/mixins/Credits';
import CreditsConsumer from '@/mixins/CreditsConsumer';

const INITIAL_PAGE = 0;
const LIMIT_REQUEST = 1;

@Component({
  components: {
    PageHeader,
    BoxContainer,
    Question,
    QuestionLoading,
    QuestionAlternative,
    AlternativeObjective,
    AlternativeTruthyOrFalsy,
    AlternativeSummation,
    AlternativeRelateColumns,
    SubjectiveQuestionInput,
    QuestionHelpResolution,
    VideoHelpResolution,
    ResolutionRelateColumns,
    ResolutionSummation,
    ResolutionTruthyOrFalsy,
    FeedbackUser,
    ButtonMovie,
    ButtonReportProblem,
    RelatedQuestions,
    RelatedQuestionsLoading,
    Footer,
    ContainerFluid,
    ExLoading,
    EyeIcon,
    Loading,
    // ModalCreditsWarning,
  },
  filters: {
    formatNumber(value: number) {
      return value < 10 ? `0${value}` : `${value}`;
    },
  },
})
export default class AnswerQuestion extends Mixins(CreditsConsumer) {
  private tagTypeModel = TAG_TYPE_MODEL;
  private typeResolution = TYPE_RESOLUTION;
  private isLoadingQuestion = false;

  private showAnswer = false;
  private isShowAnswer = false;

  private idOptionAnswer: number | null = null;
  private allAnsweredTrueOrFalse = false;
  private optionsAnswer: Array<OptionsAnswer> = [];
  private optionsAnswerTrueOrFalse: Array<OptionsAnswerTrueOrFalse> = [];
  // private idsQuestionsAnswered: Array<number> = [];

  private question: IQuestion | null = null;
  private relatedQuestions: Array<IQuestion> = [];

  private QuestionService = new QuestionService();
  private SimulationService = new SimulationService();
  // private HistoricService = new HistoricService();

  // private setModal = new ActiveModal();

  // created() {
  // this.checkCredits();
  // this.getLastQuestionViewed();
  // }

  mounted() {
    this.setBreadCrumbs();
  }

  get idQuestion() {
    return Number(this.$route.params.id);
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get isMovie() {
    return this.$store.getters.isMovie;
  }

  get questionResults() {
    let options: OptionsAnswer[] | OptionsAnswerTrueOrFalse[] = this.optionsAnswer;

    if (this.model === this.tagTypeModel.TRUTHY_OR_FALSE) {
      options = this.optionsAnswerTrueOrFalse;
    }

    return {
      ...this.question,
      options,
    };
  }

  get model() {
    if (!this.question) return '';

    const tag = this.getTag(this.question?.tags, TAG_TYPES.MODEL);

    return tag?.name || this.tagTypeModel.OBJECTIVE;
  }

  get enableSubmitButton() {
    if (this.model === this.tagTypeModel.TRUTHY_OR_FALSE) return this.allAnsweredTrueOrFalse;

    if (this.model === this.tagTypeModel.SUMMATION) return this.summationQuestion;

    if (this.model === this.tagTypeModel.RELATE_COLUMNS) return this.relateColumnsQuestion;

    if (this.model === this.tagTypeModel.SUBJECTIVE) return this.contentSubjectiveQuestion.length;

    return this.idOptionAnswer;
  }

  get summationQuestion() {
    return this.$store.getters.summationQuestion.answer !== null;
  }

  get relateColumnsQuestion() {
    const answersQuestion = this.$store.getters.answersRelateColumns;

    if (!answersQuestion.length) return false;

    return answersQuestion.every((answer: IRelateColumnsAnswers) => answer.selectedIndex !== null);
  }

  get contentSubjectiveQuestion() {
    return this.$store.getters.contentSubjectiveQuestion;
  }

  get getTypeResolution() {
    if (this.model === TAG_TYPE_MODEL.RELATE_COLUMNS) return this.typeResolution.RELATE_COLUMNS;

    if (this.model === TAG_TYPE_MODEL.SUMMATION) return this.typeResolution.SUMMATION;

    if (this.model === TAG_TYPE_MODEL.TRUTHY_OR_FALSE) return this.typeResolution.TRUTHY_FALSY;

    return this.typeResolution.OBJECTIVE;
  }

  get resolutions() {
    if (!this.question) return [];

    const resolutions = this.question.options.map((option) => ({
      content: option.resolution || '',
    }));

    return resolutions;
  }

  get plan() {
    return this.$store.getters.profile?.plan;
  }

  get isPlanFree() {
    return this.plan?.planID === LIST_PLAN_ID.FREE;
  }

  // get totalCredits(): number {
  //   return this.creditData?.credits || 0;
  // }

  // get textCredits() {
  //   const remainingCredits = this.getRemainingCredits;
  //   const { totalCredits } = this;
  //   const remainingCreditsText = remainingCredits > 1 ? 'créditos' : 'crédito';
  //   const totalCreditsText = totalCredits > 1 ? 'disponíveis' : 'disponível';

  //   return `${remainingCredits} ${remainingCreditsText} de ${totalCredits} ${totalCreditsText}.`;
  // }

  // get answeredQuestionThisMonth() {
  //   const idQuestion = Number(this.$route.params.id);

  //   const answeredQuestion = this.idsQuestionsAnswered.length ? this.idsQuestionsAnswered.findIndex((id: number) => id === idQuestion) : -1;

  //   return answeredQuestion !== -1;
  // }

  get showButtonAnswer() {
    return !this.isShowAnswer && this.isPlanFree;
  }

  @Watch('$route', {
    immediate: true,
  })
  async loadQuestion() {
    this.showAnswer = false;
    await this.getQuestion();
  }

  @Watch('creditData', {
    deep: true,
    immediate: true,
  })
  setShowDoubt() {
    const { actions } = this.creditData;

    if (!actions) {
      this.isShowAnswer = false;

      return;
    }

    const foundDoubt = actions.find((action) => action.material_id === Number(this.idQuestion) && action.material_type === MATERIAL_TYPES.QUESTION && action.action_id === ACTIONS.VIEW_ANSWER_QUESTION);

    this.isShowAnswer = !!foundDoubt;
  }

  @Watch('isPlanFree', {
    immediate: true,
  })
  setIsShowAnswer() {
    this.isShowAnswer = !this.isPlanFree;
  }

  async getQuestion() {
    try {
      this.isLoadingQuestion = true;

      if (!this.idQuestion) {
        this.redirectToQuestions();
        return;
      }

      const data = this.setDataGetQuestion();

      const response = await this.QuestionService.getQuestion(data);

      if (response.questions.length) {
        const question = this.treatmentQuestion(response.questions[0]);

        this.question = question;
      }

      if (response.relatedQuestions.length) this.relatedQuestions = response.relatedQuestions;

    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar a questão.',
        status: 'error',
      });
      console.error({ error });
    } finally {
      this.isLoadingQuestion = false;
    }
  }

  setDataGetQuestion() {
    const data: GetQuestions = {
      id: [this.idQuestion],
      page: INITIAL_PAGE,
      limit: LIMIT_REQUEST,
      isNewGetQuestion: true,
      isComplete: true,
      withoutCache: true,
    };

    return data;
  }

  redirectToQuestions() {
    this.$router.push({
      name: 'Questions',
    });
  }

  // async getLastQuestionViewed() {
  //   try {
  //     if (!this.isPlanFree) return;

  //     const response = await this.HistoricService.getLastQuestionsViewed();

  //     if (response.length) {
  //       this.idsQuestionsAnswered = this.getQuestionIds(response);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // getQuestionIds(questions: Array<QuestionViewed>) {
  //   return questions.map((question: QuestionViewed) => question.question.ID);
  // }

  treatmentQuestion(questionItem: IQuestion) {
    if (!questionItem) return null;

    const question = questionItem.contentfix;

    const resolution = questionItem?.resolution?.replace(
      /<br[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>|(<p>[^a-zA-ZÀ-ü0-9]*(&nbsp;)<\/p>)/g,
      '',
    ) || null;

    const options = shuffle(this.treatmentOptions(questionItem.options));

    return {
      ...questionItem,
      question,
      resolution,
      options,
    };
  }

  treatmentOptions(options: Array<IOption>) {
    return options.map((option: IOption) => {
      const title = option.content;

      return {
        ...option,
        title,
      };
    });
  }

  handleObjectiveQuestionAnswer(indexAlternative: number, idQuestion: number, indexQuestion: number, idOption: number) {
    if (!this.question || !this.question?.options.length) return;

    this.idOptionAnswer = idOption;

    this.optionsAnswer = this.question.options.map((option: IOption) => ({
      ...option,
      idOptions: option.ID,
      correct: option.validated,
      answerOptionID: idOption,
    }));
  }

  handleTrueOrFalseQuestionAnswers(alternatives: INewAlternativesTrueOrFalse[]) {
    if (!this.question || !this.question?.options.length) return;

    this.optionsAnswerTrueOrFalse = this.question.options.map((option: IOption) => {
      const alternativeFound = alternatives.find((alternative: INewAlternativesTrueOrFalse) => alternative.ID === option.ID);

      return {
        ...option,
        answerOption: alternativeFound ? alternativeFound.selectedOption : null,
      };
    });

    this.allAnsweredTrueOrFalse = this.checkAllAnswersTrueOrFalse(alternatives);
  }

  checkAllAnswersTrueOrFalse(alternatives: INewAlternativesTrueOrFalse[]) {
    return alternatives.every((alternative: INewAlternativesTrueOrFalse) => alternative.selectedOption !== null);
  }

  handleShowAnswer() {
    // if (this.isPlanFree && this.answeredQuestionThisMonth) {
    //   this.viewAnswer();
    //   return;
    // }

    // if (this.isPlanFree && !this.answeredQuestionThisMonth && this.hasCreditsQuestion) {
    //   this.showModalCreditsWarning();
    //   return;
    // }

    // if (this.isPlanFree && !this.hasCreditsQuestion) {
    //   this.showModalCredits();
    //   return;
    // }

    this.viewAnswer();
  }

  async saveQuestionAnswered() {
    try {
      await this.SimulationService.saveQuestionAnswered(this.idQuestion);

    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao salvar questão respondida.',
        status: 'error',
      });
    }
  }

  handleShowMoreQuestions() {
    const disciplineID = this.question?.discipline.ID;
    const topicID = this.question?.topic.ID;

    this.$router.push({
      name: 'ListQuestions',
      query: {
        disciplineID: String(disciplineID),
        topicID: String(topicID),
      },
    });
  }

  setBreadCrumbs(currentNamePage = 'Questão') {
    this.$breadcrumb.set([
      { title: 'Banco de Questões', to: '/questoes' },
      { title: currentNamePage, to: null },
    ]);
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'AnswerQuestion',
          local: 'Responder questão',
        },
      },
    });
  }

  // checkCredits() {
  //   if (!this.isPlanFree) return;

  //   this.getCredits(ContextOptionsCredits.questions);
  // }

  viewAnswer() {
    this.setTrackAmplitude();

    this.showAnswer = !this.showAnswer;

    this.setBreadCrumbs('Resultado');

    // if (this.isPlanFree && this.answeredQuestionThisMonth) return;

    // this.saveQuestionAnswered();
  }

  // showModalCreditsWarning() {
  //   this.setModal.activeModal('modalCreditsWarning');
  // }

  // showModalCredits() {
  //   this.setModal.activeModal('modalCredits');
  // }

  getTag(tags: ITag[], tagType: number) {
    return tags.find((tag) => tag.tagTypesID === tagType);
  }

  async handleClickShowAnswer() {
    if (!this.isPlanFree) return;

    this.isShowAnswer = await this.postCreditsConsumer({
      actionID: ACTIONS.VIEW_ANSWER_QUESTION,
      materialID: this.idQuestion,
      materialTypeID: MATERIAL_TYPES.QUESTION,
    });
  }
}
